$primary: #FFCC00;
$secondary: #6c757d;
$success: #E3010F;
$info: #3B2B98;
$warning: #ffc107;
$danger: #ec5b53;
$light: #f8f9fa;
$dark: #3B3663;
$btn-focus-box-shadow: none !important;

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&display=swap');
@import '~bootstrap/scss/bootstrap';

* {
  transition-duration: 300ms;
}

.pointer {
  cursor: pointer
}

::selection {
  background-color: #D2E8C4;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

}

body::-webkit-scrollbar-thumb {
  background: rgb(96, 87, 150);
  background: -moz-linear-gradient(-155deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  background: -webkit-linear-gradient(-155deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  background: linear-gradient(-155deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#605796", endColorstr="#3b2b98", GradientType=1);
  border-radius: 1rem;
}

.w-md-45 {
  @media (max-width: 991.98px) {
    width: 45%;
  }
}

.w-lg-50 {
  @media (min-width: 992px) {
    width: 50% !important;
  }
}

.w-lg-25 {
  @media (min-width: 992px) {
    width: 25% !important;
  }
}

body,
html {
  font-family: 'Mulish', sans-serif;
}

.no-list>li {
  list-style: none;
  font-size: 0.8rem;
}

.justify-content-lg-end {
  @media (min-width: 992px) {
    justify-content: end;
  }
}

.bg-md-translucent {
  @media (max-width: 991.98px) {
    background: rgba(255, 255, 255, 0.6);
  }
}

.bg-md-dark {
  @media (max-width: 991.98px) {
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 1rem;
  }
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #072366 !important;
}

.bg-gradient-primary {
  background: rgb(255, 193, 7);
  background: -moz-linear-gradient(90deg, rgba(255, 193, 7, 1) 0%, rgba(227, 1, 15, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 193, 7, 1) 0%, rgba(227, 1, 15, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 193, 7, 1) 0%, rgba(227, 1, 15, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc107", endColorstr="#e3010f", GradientType=1);
}

.bg-gradient-secondary {
  background: rgb(96, 87, 150);
  background: -moz-linear-gradient(90deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  background: linear-gradient(90deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#605796", endColorstr="#3b2b98", GradientType=1);
}

.text-gradient-primary {
  background: rgb(255, 193, 7);
  background: -moz-linear-gradient(90deg, rgba(255, 193, 7, 1) 0%, rgba(227, 1, 15, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 193, 7, 1) 0%, rgba(227, 1, 15, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 193, 7, 1) 0%, rgba(227, 1, 15, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc107", endColorstr="#e3010f", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-secondary {
  background: rgb(96, 87, 150);
  background: -moz-linear-gradient(90deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  background: linear-gradient(90deg, rgba(96, 87, 150, 1) 0%, rgba(59, 43, 152, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#605796", endColorstr="#3b2b98", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.data-card-body {
  position: relative;
}

.data-card-front {
  z-index: 10;
  background: #f8f9fa;
  border-radius: 0.25rem;
  position: relative;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
}

.data-card-body:hover .data-card-front {
  transform: translateY(-10px);
  background: #3b3663;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .4);
}

.data-card-title {
  color: #3b3663;
}

.data-card-back1,
.data-card-back2 {
  position: absolute;
  height: 100px;
  bottom: 0px;
  left: 50%;
  border-radius: 0.25rem;
  background: #3b3663;
  transform: translateX(-50%);
}

.data-card-back1 {
  opacity: 0.3;
  width: 98%;
}

.data-card-back2 {
  opacity: 0.2;
  width: 96%;
}

.data-card-body:hover .data-card-back1 {
  transform: translateX(-50%) translateY(5px);
}

.data-card-body:hover .data-card-back2 {
  transform: translateX(-50%) translateY(15px);
}


.data-card-body:hover .data-card-title {
  color: #f8f9fa;
}

.data-card-icon0 {
  background-image: url('./img/patch_bg0.png');
}

.data-card-icon1 {
  background-image: url('./img/patch_bg1.png');
}

.data-card-icon2 {
  background-image: url('./img/patch_bg2.png');
}

.data-card-icon {
  position: relative;
  color: black;
  transition-duration: 10ms;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  mix-blend-mode: difference;
  z-index: 2;
}

.data-card-body:hover .data-card-icon {

  @keyframes spin {
    from {
      left: 0;
      transform: translateX(0) rotate(0deg);
    }

    to {
      left: 100%;
      transform: translateX(-100%) rotate(360deg);
    }
  }

  color: #f8f9fa;
  animation: spin 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.data-card-seperator {
  height: 1px;
  background-color: #3b3663;
  width: 35%;
  border: none;
  margin: 15px 0 25px 0;
}

.data-card-body:hover .data-card-seperator {
  background-color: #f8f9fa;

}


.data-card-text {
  color: #3B2B98
}


.data-card-body:hover .data-card-text {
  color: #f8f9fa;
}

.progress-bar {
  background-color: transparent !important;
}

.progress-bar-rect-wrapper>div {
  border-radius: 100px !important;
}